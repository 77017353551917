@media print {

  header {
    display: none !important;
  }

  .no-print {
    display: none !important;
  }
}
.progress {
  position: absolute;
  top: 50%;
  left: 50%;
}
